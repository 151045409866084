import axios from 'axios';
import querystring from 'querystring';

const urlString = '/signatures';

export default {

    index (filters = {}) {
        return axios.get(urlString + '?' + querystring.stringify(filters)).then(response => {
            return response;
        });
    },

    update (data) {
        return axios.put(`${urlString}/${data.id}`, data).then(response => {
            return response;
        });
    },

    delete (itemId) {
        return axios.delete(`${urlString}/${itemId}`).then(response => {
            return response;
        });
    },

    create (data) {
        return axios.post(`${urlString}`, data).then(response => {
            return response;
        });
    }

};
