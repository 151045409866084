<template>
  <v-app>
    <v-navigation-drawer v-model="showLeftNavigation" app>
      <!-- -->
    </v-navigation-drawer>

    <v-app-bar
        app
    >
      <template v-slot:img="{ props }">
        <v-img
            gradient="to top right, rgba(255,255,255,1), rgba(255,255,255,.8)"
            v-bind="props"
        ></v-img>
      </template>
      <template v-slot:extension>
        <template v-if="isAuthenticated">
          <v-container>
            <menu-component></menu-component>
          </v-container>
        </template>

      </template>
      <v-container fill-height>
        <v-avatar class='mr-5'>
          <v-icon color="primary" x-large>mdi-application</v-icon>
        </v-avatar>
        <v-app-bar-nav-icon v-if="isMobile"></v-app-bar-nav-icon>
        <v-toolbar-title class="mr-5">
          QE
        </v-toolbar-title>


        <v-spacer></v-spacer>
        <entity-search v-if="isAuthenticated"></entity-search>
        <user-menu></user-menu>


      </v-container>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main id="mainmain">
      <!-- Provides the application the proper gutter -->
      <v-container>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app color="primary" dark dense>
      <v-container>
        &copy; {{ year }} Hercules Health Technologies
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import MenuComponent from '@/components/Menu';
  import EntitySearch from '@/components/EntitySearch';
  import UserMenu from './components/UserMenu';

  export default {
    name: 'App',
    components: {
      EntitySearch,
      MenuComponent,
      UserMenu
    },
    props: {},
    data () {
      return {
        activeTab: 1,

        showLeftNavigation: false
      };
    },
    computed: {
      ...mapGetters('user', {
        isAuthenticated: 'isAuthenticated'
      }),
      isMobile () {
        return false;
      },
      year () {
        return new Date().getFullYear();
      }
    },
    methods: {
      ...mapActions('user', {
        deauthenticate: 'deauthenticate'
      }),
      logout () {
        this.deauthenticate().then(() => {
          this.$router.push({name: 'Login'});
        });
      }
    },

  };
</script>

<style scoped>

</style>
