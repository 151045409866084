import axios from 'axios';
import querystring from 'querystring';

const urlString = '/users';
export default {
    authenticateUser (formData) {
        return axios.get('/sanctum/csrf-cookie', {baseURL: '/'}).then(response => {
            return axios.post('/login', formData, {baseURL: '/'}).then(response => {
                return response;
            }).catch(error => console.error(error)); // credentials didn't match
        });
    },
    registerUser (formData) {
        return axios.post('/register', formData, {baseURL: '/'}).then(response => {
            return response;
        }).catch(error => console.error(error)); // account couldn't be created
    },
    deauthenticateUser () {
        return axios.post('/logout', {}, {baseURL: '/'}).then(response => {
            return response;
        });
    },
    fetchUser () {
        return axios.get('/user').then(response => {
            return response;
        });
    },
    updatePassword (formData) {
        return axios.post('/profile/password', formData).then((response) => {
            return response;
        }).catch(error => console.error(error)); // account couldn't be created
    },
    index (filters = {}) {
        return axios.get(urlString + '?' + querystring.stringify(filters)).then(response => {
            return response;
        });
    },

    update (data) {
        return axios.put(`${urlString}/${data.id}`, data).then(response => {
            return response;
        });
    },

    delete (itemId) {
        return axios.delete(`${urlString}/${itemId}`).then(response => {
            return response;
        });
    },

    create (data) {
        return axios.post(`${urlString}`, data).then(response => {
            return response;
        });
    }

};

