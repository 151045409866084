// src/plugins/vuetify.js

import Vue from 'vue';

import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

Vue.component('v-text-field', VTextField);
const options = {
    locale: 'en-US',
    currency: 'USD',
    currencyDisplay: 'symbol',
    precision: 2,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    exportValueAsInteger: false,
    autoSign: false,
    useGrouping: true
};

Vue.use(VCurrencyField, options);
