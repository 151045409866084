// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VuetifyDialogPromise from 'vuetify-dialog-promise';

Vue.use(Vuetify);

Vue.use(VuetifyDialogPromise, {
    locale: 'en',
    snackbarX: 'right',
    snackbarY: 'top',
});

const opts = {
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                primary: '#4F6D7A',
                secondary: '#DD6E42',
                accent: '#E8DAB2',
                error: '#D37C2B',
                info: '#47bdf1',
                success: '#4CAF50',
                warning: '#D37C2B',
            }
        },
    },
};

const vuetify = new Vuetify(
    opts
);

export default vuetify;
