<template>
  <div>
    <template v-if="isAuthenticated">

      <v-menu offset-y right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            mdi-account
          </v-icon>
        </template>
        <v-list>

          <v-list-item @click="viewProfile">
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>

          <v-list-item
              @click="logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </template>

    <template v-if="!isAuthenticated">
      <v-btn text @click="$router.push('/login').catch(()=>{})">
        Login
      </v-btn>
    </template>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'UserMenu',
    components: {},
    props: {},
    data () {
      return {};
    },
    computed: {
      ...mapGetters('user', {
        isAuthenticated: 'isAuthenticated'
      }),
    },
    methods: {
      logout () {
        this.$router.push('/logout').catch(() => {
        });
      },
      viewProfile () {
        this.$router.push('/profile').catch(() => {
        });
      }
    },
    created () {
    },
    mounted () {
    },
  };
</script>

<style scoped>

</style>
