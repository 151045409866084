import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { get } from 'lodash-es';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Home.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Profile.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/quotes',
        name: 'Quotes',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Quotes.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/quote/:quoteId',
        name: 'Quote',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Quote.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/carriers',
        name: 'Carriers',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Carriers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/carrier/:carrierId',
        name: 'Carrier',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Carrier.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/producers',
        name: 'Producers',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Producers.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/producer/:producerId',
        name: 'Producer',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Producer.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/underwriters',
        name: 'Underwriters',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Underwriters.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/underwriter/:underwriterId',
        name: 'Underwriter',
        component: () => import(/* webpackChunkName: "views" */ '@/views/Underwriter.vue'),
        props: true,
        meta: {
            auth: true
        },
    },

    {
        path: '/settings/eftemplates',
        name: 'EligibilityTemplates',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/EligibilityTemplates.vue'),
        props: true,
        meta: {
            auth: true
        },
    },

    {
        path: '/settings/eftemplate/:eligibilityTemplateId',
        name: 'EligibilityTemplate',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/EligibilityTemplate.vue'),
        props: true,
        meta: {
            auth: true
        },
    },

    {
        path: '/settings/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Users.vue'),
        props: true,
        meta: {
            auth: true,
            userType: 'admin'
        },
    },
    {
        path: '/user/:userId',
        name: 'User',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/User.vue'),
        props: true,
        meta: {
            auth: true,
            userType: 'admin'
        },
    },
    {
        path: '/settings/messagetemplates',
        name: 'MessageTemplates',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/MessageTemplates.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/signatures',
        name: 'Signatures',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Signatures.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/benefits',
        name: 'Benefits',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Benefits.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/tiers',
        name: 'Tiers',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Tiers.vue'),
        props: true,
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/contracttypes',
        name: 'ContractTypes',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/ContractTypes.vue'),
        props: true,
        meta: {
            auth: true
        },
    },

    {
        path: '/generalsettings/:machineName',
        name: 'GeneralSetting',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Setting.vue'),
        props: true,
        meta: {
            auth: true
        },
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
        meta: {
            auth: false
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Logout.vue'),
    },

];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !get(store, 'state.user.authenticated', false)) {
        next({name: 'Login'});
    }
    if (to.meta.auth && to.meta.userType && !get(store, 'state.user.authenticated', false)
        && !get(store, 'state.user.userType', false) == to.meta.userType
    ) {
        next({name: 'Login'});
    } else {
        next();
    }
});

export default router;
