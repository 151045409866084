<template>

  <div v-click-outside="stopSearching"
       class="search"
  >
    <v-text-field
        ref="searchField"
        v-model="searchQuery"
        autocomplete="off"
        class="align-center"
        clearable
        hide-details
        prepend-icon="mdi-magnify"
        @focus="startSearching"
        @keyup="search"
    ></v-text-field>

    <div v-show="searching && Object.keys(searchResults).length" class="results">
      <v-list dense flat>
        <template v-for="itemType in Object.keys(searchResults)">
          <v-subheader class="categoryHeader">{{ itemType }}</v-subheader>
          <v-list-item
              v-for="item in searchResults[itemType]"
              :key="`${itemType}_${item.id}`"
              @click="handleItemClick(itemType, item.id)"
          >
            <v-list-item-content
                @click="handleItemClick(itemType, item.id)"
            >
              <v-list-item-title v-text="item.text"></v-list-item-title>

              <v-list-item-subtitle
                  v-if="item.subText"
                  v-text="item.subText"
              ></v-list-item-subtitle>
            </v-list-item-content>


          </v-list-item>
        </template>
      </v-list>
    </div>

  </div>

</template>

<script>
  import searchApi from '@/api/search';
  import { debounce } from 'lodash-es';

  export default {
    name: 'EntitySearch',
    components: {},
    props: {},
    data () {
      return {
        searching: false,
        searchQuery: '',
        searchResults: {}
      };
    },
    computed: {},
    methods: {
      handleItemClick (itemType, itemId) {

        if (itemType == 'Carriers') {
          this.$router.push(`/carrier/${itemId}`).catch(() => {
          });
        }

        if (itemType == 'Producers') {
          this.$router.push(`/producer/${itemId}`).catch(() => {
          });
        }

        if (itemType == 'Underwriters') {
          this.$router.push(`/underwriter/${itemId}`).catch(() => {
          });
        }

        if (itemType == 'Quotes') {
          this.$router.push(`/quote/${itemId}`).catch(() => {
          });
        }

        if (itemType == 'Users') {
          this.$router.push(`/user/${itemId}`).catch(() => {
          });
        }

        this.stopSearching();
      },
      startSearching () {
        this.searching = true;
      },
      stopSearching () {
        this.searching = false;
        this.searchQuery = '';
        this.searchResults = {};
      },
      search: debounce(function () {
        searchApi.search(this.searchQuery).then(({data}) => {
          this.searchResults = data;
        });
      }, 250)
    },
    created () {
    },
    mounted () {
    },
    watch: {}
  };
</script>

<style scoped>
  .search {
    position: relative;

  }

  .categoryHeader {
    font-size: 1em;
    font-weight: bold;
    color: var(--v-primary-base);
  }

  .results {
    position: absolute;
    right: 0;
    width: calc(100% - 30px);
    z-index: 200;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 12px 15px -12px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 12px 15px -12px rgba(0, 0, 0, 0);
  }
</style>
