export default [
    {
        icon: 'mdi-home',
        title: 'Home',
        link: '/',
        children: [],
    },
    {
        icon: 'mdi-account',
        title: 'Producers',
        link: '/producers',
        children: [],
    },
    {
        icon: 'mdi-office-building',
        title: 'Carriers',
        link: '/carriers',
        children: [],
    },

    {
        icon: 'mdi-account-tie',
        title: 'Underwriters',
        link: '/underwriters',
        children: [],
    },
    {
        icon: 'mdi-cog',
        title: 'Settings',
        children: [
            {
                icon: 'mdi-shield-account',
                title: 'Users',
                link: '/settings/users',
                userType: 'admin'
            },
            {
                icon: 'mdi-email-variant',
                title: 'Email Templates',
                link: '/settings/messagetemplates',
            },
            {
                icon: 'mdi-signature',
                title: 'Email Signatures',
                link: '/settings/signatures',
            },
            {
                icon: 'mdi-email-newsletter',
                title: 'Default Email Subject',
                link: '/generalsettings/defaultEmailSubject',
            },
            {
                icon: 'mdi-umbrella',
                title: 'Benefits',
                link: '/settings/benefits',
            },
            {
                icon: 'mdi-counter',
                title: 'Contract Types',
                link: '/settings/contracttypes',
            },
            {
                icon: 'mdi-sort-variant',
                title: 'Tiers',
                link: '/settings/tiers',
            },
            {
                icon: 'mdi-file-cog',
                title: 'Elig. File Templates',
                link: '/settings/eftemplates',
            },
            {
                icon: 'mdi-notebook',
                title: 'Default Contingency Text',
                link: '/generalsettings/defaultContingencyText',
            },
            {
                icon: 'mdi-map-marker',
                title: 'Address',
                link: '/generalsettings/addressText',
            },
            {
                icon: 'mdi-page-layout-footer',
                title: 'Footer',
                link: '/generalsettings/footerText',
            },
            {
                icon: 'mdi-calendar-range',
                title: 'Default Proposal Validity Length',
                link: '/generalsettings/defaultValidLength',
            },

        ],
    },
];

