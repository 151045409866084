import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import benefit from './benefit';
import carrier from './carrier';
import census from './census';
import contracttype from './contracttype';
import eligibilitytemplate from './eligibilitytemplate';
import membercount from './membercount';
import messagetemplate from './messagetemplate';
import message from './message';
import note from './note';
import producer from './producer';
import quote from './quote';
import quoteoption from './quoteoption';
import quoterate from './quoterate';
import setting from './setting';
import signature from './signature';
import tierstructure from './tierstructure';
import tierstructureitem from './tierstructureitem';
import tpa from './tpa';
import underwriter from './underwriter';
import user from './user';

const vuexLocal = new VuexPersistence({
    storage: sessionStorage,
    key: 'luquote',
    modules: [
        'benefit',
        'carrier',
        'census',
        'contracttype',
        'eligibilitytemplate',
        'membercount',
        'message',
        'messagetemplate',
        'note',
        'producer',

        'quote',
        'quoterate',
        'quoteoption',
        'setting',
        'signature',
        'tierstructure',
        'tierstructureitem',
        'tpa',
        'underwriter',
        'user',
    ],
});

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        benefit,
        carrier,
        census,
        contracttype,
        eligibilitytemplate,
        membercount,
        message,
        messagetemplate,
        note,
        producer,
        quote,
        quoterate,
        quoteoption,
        setting,
        signature,
        tierstructure,
        tierstructureitem,
        tpa,
        underwriter,
        user,
    },
    plugins: [ vuexLocal.plugin ]
});

export default store;
