import Vue from 'vue';
import { format } from 'date-fns';

Vue.filter('formatDate', (value) => {
    if (!value || value == '') return '';
    return format(new Date(value), 'MM/dd/yyyy');
});

Vue.filter('formatDateTime', (value) => {
    if (!value || value == '') return '';
    return format(new Date(value), 'MM/dd/yyyy h:mm:ss a');
});
