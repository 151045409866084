import axios from 'axios';

const urlString = '/search';

export default {

    search (query) {
        return axios.get(urlString + '?query=' + query).then(response => {
            return response;
        });
    },

};
