import user from '@/api/user';

export default {
    namespaced: true,
    state: {
        authenticated: false,
        username: '',
        name: '',
        userType: '',
        userId: '',
        all: [],
    },

    getters: {
        isAuthenticated (state) {
            return state.authenticated;
        }
    },

    actions: {
        authenticate ({
                          commit,
                          dispatch
                      }, formData) {
            user.authenticateUser(formData).then(({data}) => {
                commit('AUTHENTICATE', data);
                dispatch('fetchUser');
            });
        },

        deauthenticate ({commit}) {
            user.deauthenticateUser();
            commit('DEAUTHENTICATE');
        },
        fetchUser ({commit}) {
            user.fetchUser().then(({data}) => {
                commit('FETCH', data);
            });
        },
        index ({commit}, query) {
            user.index(query).then(({data}) => {
                commit('INDEX', data);
            });
        },
        read ({commit}, id) {
            user.read(id).then(({data}) => {
                commit('READ', data);
            });
        },
        create ({commit}, formData) {
            user.create(formData).then(({data}) => {
                commit('READ', data);
            });
        },
        update ({commit}, formData) {
            user.update(formData).then(({data}) => {
                commit('READ', data);
            });
        },
        delete ({commit}, id) {
            user.delete(id).then(() => {
                commit('DELETE', id);
            });
        },
        updatePassword ({}, formData) {
            return user.updatePassword(formData);
        }
    },

    mutations: {
        AUTHENTICATE (state, data) {
            state.authenticated = true;
        },
        DEAUTHENTICATE (state) {
            state.username = '';
            state.authenticated = false;
        },
        FETCH (state, data) {
            state.username = data.email;
            state.name = data.name;
            state.userType = data.userType;
            state.userId = data.id;
        },
        INDEX (state, responseData) {
            state.all = responseData;
        },
        READ (state, responseData) {
            const idx = state.all.findIndex(a => a.id == responseData.id);
            if (idx >= 0) {
                state.all.splice(idx, 1, responseData);
            } else {
                state.all.push(responseData);
            }
        },
        DELETE (state, id) {
            const idx = state.all.findIndex(a => a.id == id);
            if (!idx >= 0) {
                state.all.splice(idx, 1);
            }
        },
    }

};
